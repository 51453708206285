<!-- 创建企业 未创建企业的用户会自动跳转到此界面 -->
<template>
  <div class="creat">
    <div v-if="$store.state.regStep == 1">
      <div class="set-com-type flex">
        <div
          @click="ruleForm.comp_type = 1"
          :class="{ active: ruleForm.comp_type == 1 }"
          class="flex flex-pack-center block"
        >
          <div>
            <div class="block-title">家具工厂</div>
            <div style="padding-top: 50px" class="block-inner">
              <img
                style="width: 126px"
                src="../../assets/images/login/jiaju1.png"
              />
              <!-- <span class="icon iconfont icon-bianzu16"></span> -->
            </div>
          </div>
        </div>
        <div
          @click="ruleForm.comp_type = 2"
          :class="{ active: ruleForm.comp_type == 2 }"
          class="flex flex-pack-center block"
        >
          <div>
            <div class="block-title">家具设计公司</div>
            <div style="padding-top: 45px" class="block-inner">
              <img
                style="width: 126px"
                src="../../assets/images/login/jiaju2.png"
              />
              <!-- <span class="icon iconfont icon-bianzu15"></span> -->
            </div>
          </div>
        </div>
        <div
          @click="ruleForm.comp_type = 3"
          :class="{ active: ruleForm.comp_type == 3 }"
          class="flex flex-pack-center block"
        >
          <div>
            <div class="block-title">家具销售公司</div>
            <div style="padding-top: 20px" class="block-inner">
              <img
                style="width: 126px"
                src="../../assets/images/login/jiaju3.png"
              />
              <!-- <span class="icon iconfont icon-icon-test3"></span> -->
            </div>
          </div>
        </div>
        <div
          @click="ruleForm.comp_type = 6"
          :class="{ active: ruleForm.comp_type == 6 }"
          class="flex flex-pack-center block"
        >
          <div>
            <div class="block-title">家具供应商</div>
            <div class="block-inner" style="padding-top: 46px; color: #ccc">
              <!-- <img style="width:180px;" src="../../assets/images/login/jiaju4.png"> -->
              <img
                style="width: 126px"
                src="../../assets/images/login/jiaju4_active.png"
              />
              <!-- <span class="icon iconfont icon-qxgl_bumen"></span> -->
            </div>
          </div>
        </div>
        <div
          @click="ruleForm.comp_type = 7"
          :class="{ active: ruleForm.comp_type == 7 }"
          class="flex flex-pack-center block"
        >
          <div>
            <div class="block-title">家具物流公司</div>
            <div class="block-inner" style="padding-top: 46px; color: #ccc">
              <!-- <img style="width:180px;" src="../../assets/images/login/jiaju4.png"> -->
              <img
                style="width: 126px"
                src="../../assets/images/login/jiaju5_active.png"
              />
              <!-- <span class="icon iconfont icon-qxgl_bumen"></span> -->
            </div>
          </div>
        </div>
        <div
          @click="ruleForm.comp_type = 8"
          :class="{ active: ruleForm.comp_type == 8 }"
          class="flex flex-pack-center block"
        >
          <div>
            <div class="block-title">金融机构</div>
            <div class="block-inner" style="padding-top: 46px; color: #ccc">
              <!-- <img style="width:180px;" src="../../assets/images/login/jiaju4.png"> -->
              <img
                style="width: 126px"
                src="../../assets/images/login/jiaju6_active.png"
              />
              <!-- <span class="icon iconfont icon-qxgl_bumen"></span> -->
            </div>
          </div>
        </div>
        <!-- <div
          @click="ruleForm.comp_type = 9"
          :class="{ active: ruleForm.comp_type == 9 }"
          class="flex flex-pack-center block"
        >
          <div>
            <div class="block-title">版权中心</div>
            <div class="block-inner" style="padding-top: 46px; color: #ccc">
              <img
                style="width: 126px"
                src="../../assets/images/login/jiaju6_active.png"
              />
            </div>
          </div>
        </div> -->
      </div>
      <!-- <div style="margin-bottom:15px;" class="flex flex-justify-around">
                <el-checkbox style="padding:5px 0;font-size:12px;color:#808080;line-height:19px;" v-model="clause">我已阅读并同意<span style="display: none;">中国</span>家居产业智联网条款</el-checkbox>
            </div> -->
      <!-- <div style="width:240px;margin:0 auto;" class="flex flex-justify-around">
                <el-button @click="goto(1)" style="width:75px;" size="small" 
                :type="clause ? 'primary' : 'info'"
                :disabled="!clause">下一步</el-button>
            </div> -->
    </div>
    <div style="width: 275px; margin: 0 auto">
      <Create1
        v-if="$store.state.regStep == 2"
        v-model="ruleForm"
        ref="form_check2"
      ></Create1>
      <Create2
        v-if="$store.state.regStep == 3"
        v-model="ruleForm"
        ref="form_check3"
      ></Create2>
    </div>
    <div
      style="width: 240px; margin: 20px auto 0"
      class="flex flex-justify-around"
    >
      <el-button
        v-if="$store.state.regStep != 1"
        @click="back()"
        style="width: 75px"
        size="small"
        type="primary"
        plain
        >上一步</el-button
      >
      <el-button
        v-if="$store.state.regStep == 1"
        @click="onStep()"
        style="width: 75px"
        size="small"
        :type="clause ? 'primary' : 'info'"
        :disabled="!clause"
        >下一步</el-button
      >
      <el-button
        v-else
        @click="onStep()"
        style="width: 75px"
        size="small"
        type="primary"
        >下一步</el-button
      >
    </div>
    <div style="text-align: center; padding-top: 15px">
      已有账号？<el-button @click="goto('/login')" type="text"
        >请登录</el-button
      >
    </div>
  </div>
</template>

<script>
import Create1 from "./Create1.vue";
import Create2 from "./Create2.vue";
export default {
  components: {
    Create1,
    Create2,
  },
  data() {
    return {
      ruleForm: {
        comp_type: null,
        comp_name: "",
        comp_short_name: "",
        province: "",
        city: "",
        county: "",
        town: "",
        comp_addr: "",
        user_name: "",
        user_mobile: "",
        user_pass: "",
        mobile_code: "",
        captcha: "",
        captcha_token: "",
      },
      rules: {
        comp: [{ required: true, message: "请输入企业名称", trigger: "blur" }],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        cpassword: [
          { required: true, message: "请再次输入密码", trigger: "blur" },
        ],
      },
      clause: true,
    };
  },
  async created() {
    // if (this.step==0) {
    //     this.$store.state.regStep=1;
    // } else if (this.step==1) {
    //     this.$store.state.regStep=2;
    // }
  },
  methods: {
    onStep() {
      console.log(this.ruleForm);
      switch (this.$store.state.regStep) {
        case 1:
          if (this.ruleForm.comp_type) {
            this.$store.state.regStep++;
          } else {
            this.$message.warning("请选择企业类型");
          }
          break;
        case 2:
          this.$refs.form_check2.submitForm2();
          break;
        case 3:
          this.$refs.form_check3.verify_phone();
          break;
      }
    },
    back() {
      if (this.$store.state.regStep > 1) {
        this.$store.state.regStep--;
      }
    },
    goto(path) {
      this.$router.push(path);
    },
    submitForm() {
      if (this.$store.state.regStep == 2) {
        this.$refs.form_check2.submitForm2();
      } else if (this.$store.state.regStep == 3) {
        this.$refs.form_check3.verify_phone();
      }
    },
  },
};
</script>
<style scoped>
.creat {
  color: rgba(41, 41, 41, 1);
  background: rgba(255, 255, 255, 1);
  height: 100%;
  overflow: hidden;
}

.set-com-type {
  width: 1200px;
  margin: 0 auto;
}
.block {
  width: 180px;
  padding: 20px 0;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px 0px rgba(200, 207, 228, 1);
  border-radius: 20px;
  margin: 20px 10px;
  position: relative;
  transition: all 0.2s ease-out;
}
.block:last-child {
  margin-right: 0;
}
.block > div {
  /* width: 100px;
        height: 100px; */
  text-align: center;
  background: rgba(255, 255, 255, 1);
  /* box-shadow: 0px 0px 8px 0px rgba(191, 185, 185, 0.5); */
  cursor: pointer;
  color: #606266;
}
.block.disable > div {
  cursor: not-allowed;
}
.block:not(.disable):hover {
  transform: scale(1.05);
}
.block:not(.disable):hover::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 4px 8px 0px rgba(200, 207, 228, 1);
  border: 1px solid rgba(80, 116, 238, 1);
  border-radius: 20px;
}
.block:not(.disable).active {
  transform: scale(1.05);
}
.block:not(.disable).active::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 4px 8px 0px rgba(200, 207, 228, 1);
  border: 1px solid rgba(80, 116, 238, 1);
  border-radius: 20px;
}
.block img {
  width: 60px;
}
.block-inner {
  /* margin-top: 10px; */
}
.block-title {
  font-size: 20px;
  color: #4d4d4d;
  font-weight: bold;
  line-height: 32px;
}
/* .set-com-type .icon{
        font-size: 64px;
    } */
</style>